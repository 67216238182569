import React from "react"
import {
  domain_card,
  domain_img,
  domain_title,
  domain_body,
  domain_container,
} from "../styles/domaincard.module.css"

const DomainCard = props => {
  const { index, title, body, img } = props

  return index !== 9 ? (
    // <Layout>
    <div
      className={`${domain_card} rounded-lg p-4 lg:p-8 flex flex-col ${
        index % 4 === 0 || (index - 1) % 4 === 0
          ? "lg:flex-row-reverse"
          : "lg:flex-row"
      } ${index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"} items-center`}
    >
      {
        <img
          src={img}
          className={`${domain_img} flex-shrink-0 mr-0 ml-0 ${
            index % 4 === 0 || (index - 1) % 4 === 0 ? "ml-2" : "mr-5"
          }`}
          alt={title}
        />
      }
      <div className={`${domain_container}`}>
        <h5 className={`${domain_title}`}>{title}</h5>
        <p className={`${domain_body}`}>{body}</p>
      </div>
    </div>
    // </Layout>
  ) : (<></>)
}

export { DomainCard }
