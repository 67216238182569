import React from "react"
import {
  banner,
  content,
  banner__container,
} from "../styles/banner.module.css"

const Banner = ({ title, text, ButtonComponent, image }) => {
  return (
    <div className={`md:py-8 xs:py-6 flex items-center justify-center ${banner}`} style={{minHeight: "60vh"}}>
      <div className={`flex items-center justify-between ${banner__container}`}>
        <div>
          <div className={`${content} md:mr-20 max-w-3xl`}>
            <p className={`md:block xs:hidden heading1 medium mb-6`}>{title}</p>
            <p className={`md:hidden heading2 regular mb-6`}>{title}</p>
            <p className={`md:block xs:hidden sub-heading medium text-justify md:text-left`} style={{lineHeight: 1.6}}>{text}</p>
            <p className={`md:hidden paragraph1 regular text-justify`} style={{lineHeight: 1.6}}>{text}</p>
          </div>
          {ButtonComponent}
        </div>
        <div className="lg:block hidden">

          <img
            src={`/Banner/${image}`}
            className={``}
            style={{minWidth: "400px", height: '400px'}}
            alt="banner"
          />
        </div>
      </div>
    </div>
  )
}

export default Banner
