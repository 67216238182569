// extracted by mini-css-extract-plugin
export var event_container = "eventcard-module--event_container--E+YXW";
export var event_card = "eventcard-module--event_card--ncWhG";
export var event_title = "eventcard-module--event_title--w+wi0";
export var event_desc = "eventcard-module--event_desc--C19gF";
export var event_btn = "eventcard-module--event_btn--UMtZ2";
export var event_img_collage = "eventcard-module--event_img_collage--gYGJ0";
export var event_img = "eventcard-module--event_img--eM3LB";
export var event_img_1 = "eventcard-module--event_img_1--dANBx";
export var event_img_2 = "eventcard-module--event_img_2--JUpB6";
export var event_img_3 = "eventcard-module--event_img_3--UM2ty";
export var event_img_4 = "eventcard-module--event_img_4--FqX3i";
export var event_img_5 = "eventcard-module--event_img_5--gIqxU";
export var minor_event_outer_container = "eventcard-module--minor_event_outer_container--79QY3";
export var minor_event_img = "eventcard-module--minor_event_img--R2ZEH";
export var minor_event_container = "eventcard-module--minor_event_container--W8w9p";
export var minor_event_title = "eventcard-module--minor_event_title--aNSKO";
export var minor_event_body = "eventcard-module--minor_event_body--4qWqu";
export var minor_event_link = "eventcard-module--minor_event_link--TVOO-";