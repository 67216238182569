export const toTop = (path) => {
  const isBrowser = typeof window !== "undefined";
  if (!path) {
    if(isBrowser) {
      window?.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  } else {
    const ele = isBrowser ? document.getElementById(path.replace("#", "")) : undefined;
    if (ele) {
      // ele?.scrollIntoView({
      //   behavior: "smooth",
      // });
    } else {
      if(isBrowser) {
        window?.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }
};
