import React, { useEffect } from "react"
import {Helmet} from "react-helmet";

import Banner from "../components/Banner"
import { DomainCard } from "../components/DomainCard"
import { EventCard } from "../components/EventCard"
import { domains } from "../components/Join_Pasc_data"
import Layout from "../components/Layout"
import { MinorEventCard } from "../components/MinorEventCard"
import {
  domain_svg_middle,
  domain_svg_top,
} from "../styles/domaincard.module.css"
import { section_title } from "../styles/whatwedo.module.css"
import { toTop } from "../utils/scroll"

const mainEvents = [
  {
    title: "PULZION",
    desc: "Pulzion is the annual flagship event conducted by PASC. It consists of various innovative and exhilarating events which help the participants enhance their skills. It has been eight years since we started organizing this annual event, and each year has seen exponential growth with immense participation bringing out a plethora of ideas.",
    btn_label: "Learn more",
    link: "../pulzion",
    images: [
      require("../images/Ellipse 1.png"),
      require("../images/Ellipse 1.png"),
      require("../images/Ellipse 1.png"),
      require("../images/Ellipse 1.png"),
      require("../images/Ellipse 1.png"),
    ],
  },

  {
    title: "PASCKATHON",
    desc: "PASC organizes an open hackathon where participants can use any technology to build their hacks and offer innovative ideas to augment their product. The meticulously compiled rounds help the participants boost their presentation skills and develop a problem-solving mindset. Usually, the first round comprises the idea presentation followed by the making of the prototype. The winning team gets awarded with certificates and exciting cash prizes.",
    btn_label: "Learn more",
    link: "../pasckathon",
    images: [
      require("../images/Ellipse 1.png"),
      require("../images/Ellipse 1.png"),
      require("../images/Ellipse 1.png"),
      require("../images/Ellipse 1.png"),
      require("../images/Ellipse 1.png"),
    ],
  },
  {
    title: "Radiance",
    desc: `The women wing of PASC has seen exponential growth since its inception. To further encourage the participation of women in STEM fields and computing, PASC-W organized a Tech - Non-Tech fest, “Radiance”. `,
    btn_label: "Learn more",
    link: "https://radiance21.pulzion.co.in/",
    // hideButton: true,
  },
]

const otherEvents = [
  {
    // image: "/radiance_logo.png",
    title: "SIGs",
    body: "The Special Interest Groups is another note-worthy activity organized by PASC, it is a group of people working in the same domain helping to learn and overcome problems. This activity immensely helps the freshers acquaint themselves with the new technologies, technical and non-technical domains. In the SIGs are conducted, various seminars and provocative discussions about projects related to upcoming technologies and domains.",
    btn_label: "Learn more",
    link: "#",
    hideButton: true,
  },
]

const WhatWeDo = props => {
  const path = props.location.hash
  useEffect(() => {
    toTop(path)
  }, [path])
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>PICT ACM Student Chapter | What We Do</title>
          <meta name="description" content="PICT ACM Student Chapter(PASC), a student chapter organization subsidiary of the Association for Computing Machinery(ACM), consists of highly motivated students, ready to learn and help each other bring the best out of them. PASC began in 2011, with the perspective of fostering technical and non-technical qualities in an individual and helping them to shape their future." />
      </Helmet>
      <Banner
        title="What happens in PASC?"
        text="PASC is the student chapter organization subsidiary of ACM.
        It is an organization that provides an opportunity for students to acquire proficiency in not only technical but also non-technical domains.
        The students can augment their skills, implement their knowledge and gain expertise in the various thought-provoking events, SIGs, workshops, and illustrious contests organized by PASC.
        "
        image="what_we_do1.png"
      />
      <section className="py-20">
        <div
          className={`px-0 md:px-12 lg:px-16 py-6 md:py-10`}
          id="events"
          style={{ marginTop: "-100px", paddingTop: "100px" }}
        >
          <h5 className={`text-5xl ${section_title} mb-8`}>Events</h5>
          <div className={`grid gap-y-4 md:gap-y-6`}>
            {mainEvents.map((item, index) => (
              <EventCard
                title={item.title}
                desc={item.desc}
                btn_label={item.btn_label}
                link={item.link}
                img={item.images}
                index={index}
                buttonVisible={item.hideButton ? false : true}
              />
            ))}
          </div>
          <div
            className={`grid grid-cols-1 lg:grid-cols-2 lg:gap-x-6 gap-y-8 mt-11`}
          >
            {otherEvents &&
              otherEvents.map(item => (
                <MinorEventCard
                  image={item.image}
                  title={item.title}
                  body={item.body}
                  link_label={item.link_label}
                  link={item.link}
                />
              ))}
          </div>
        </div>
      </section>
      <section
        className={`mt-0 lg:mt-11 relative pb-8 lg:pb-20`}
        id="domains"
        style={{ marginTop: "-100px", paddingTop: "100px" }}
      >
        <h5 className={`${section_title} text-5xl mb-8 lg:ml-20 ml-4`}>
          Domains
        </h5>
        <img
          src="/bg_svg_2.svg"
          className={`${domain_svg_top}`}
          alt="bg_svg_1"
        />
        <img
          src="/bg_svg_2.svg"
          className={`${domain_svg_middle}`}
          alt="bg_svg_2"
        />
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-16 xl:gap-x-36 gap-y-10 px-4 sm:px-8 md:px-12 lg:px-16">
          {domains &&
            domains.map((item, index) => (
              <DomainCard
                img={item.image}
                title={item.name}
                body={item.body}
                index={index}
              />
            ))}
        </div>
      </section>
    </Layout>
  )
}

export default WhatWeDo
