import React from "react"
import {
  minor_event_outer_container,
  minor_event_container,
  minor_event_img,
  minor_event_title,
  minor_event_body,
  minor_event_link,
} from "../styles/eventcard.module.css"

const MinorEventCard = props => {
  const { image, title, body, link_label, link } = props

  return (
    // <Layout>
    <div
      className={`${minor_event_outer_container} md:rounded-xl flex lg:flex-row`}
    >
      {image && (
        <img src={image} className={`${minor_event_img}`} alt="minor_event_img" />
      )}
      <div className={`${minor_event_container}`}>
        <h5 className={`${minor_event_title}`}>{title}</h5>
        <p className={`${minor_event_body}`}>{body}</p>
        {link && (
          <a href={link}>
            <button className={`${minor_event_link}`}>{link_label}</button>
          </a>
        )}
      </div>
    </div>
    // </Layout>
  )
}

export { MinorEventCard }
